import React from 'react'
import Img from 'gatsby-image'
import Slick from 'react-slick'
import * as Scroll from 'react-scroll'

import Layout from '../components/layout'
import Footer from '../components/footer'
import Mailto from '../components/mailto'

let scroll = Scroll.animateScroll

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 7000,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)

    this.intro = React.createRef()

    this.m_felix = React.createRef()
    this.c_felix = React.createRef()

    this.h_photos = React.createRef()

    this.h_produktionen = React.createRef()
    this.m_produktionen = React.createRef()
    this.c_produktionen = React.createRef()

    this.h_kontakt = React.createRef()
    this.m_kontakt = React.createRef()
    this.c_kontakt = React.createRef()
  }

  updateLayout() {
    this.intro.current.style.height =
      document.documentElement.clientHeight + 5 + 'px'
    this.c_felix.current.style.marginLeft =
      this.m_felix.current.getBoundingClientRect().left - 30 + 'px'
    this.h_produktionen.current.style.marginLeft =
      this.m_felix.current.getBoundingClientRect().left - 30 + 'px'
    this.c_produktionen.current.style.marginRight =
      document.body.clientWidth -
      this.m_produktionen.current.getBoundingClientRect().right -
      30 +
      'px'
    this.h_kontakt.current.style.marginRight =
      document.body.clientWidth -
      this.m_produktionen.current.getBoundingClientRect().right -
      30 +
      'px'
    this.c_kontakt.current.style.marginLeft =
      this.m_kontakt.current.getBoundingClientRect().left - 30 + 'px'
  }

  suggestScroll() {
    var timer = false

    var clear_timeout = function() {
      if (window.scrollY > 50) {
        clearTimeout(timer)
        window.removeEventListener('scroll', clear_timeout)
      }
    }

    window.addEventListener('scroll', clear_timeout)

    timer = window.setTimeout(function() {
      scroll.scrollTo(50, { duration: 500, smooth: 'easeInOutCubic' })

      timer = window.setTimeout(function() {
        scroll.scrollTo(0, { duration: 500, smooth: 'easeInOutCubic' })

        timer = window.setTimeout(function() {
          scroll.scrollTo(50, { duration: 500, smooth: 'easeInOutCubic' })

          timer = window.setTimeout(function() {
            scroll.scrollTo(0, { duration: 500, smooth: 'easeInOutCubic' })
          }, 600)
        }, 3000)
      }, 600)
    }, 3000)
  }

  componentDidMount() {
    this.updateLayout = this.updateLayout.bind(this)
    window.addEventListener('resize', this.updateLayout)
    this.updateLayout()
    window.setTimeout(this.updateLayout, 100)
    window.setTimeout(this.updateLayout, 500)
    window.setTimeout(this.updateLayout, 1000)

    this.suggestScroll()

    var local_slider = this.slider
    document
      .getElementsByClassName('slick-list')[0]
      .addEventListener('click', function() {
        local_slider.slickNext()
      })
  }

  render() {
    return (
      <Layout>
        <div className="intro" ref={this.intro}>
          <Img fluid={this.props.data.i0.childImageSharp.intro} />
          <h1>
            <span ref={this.m_felix}>Felix Vogel</span>
          </h1>
        </div>
        <div
          ref={this.c_felix}
          style={{ maxWidth: '500px' }}
          className="margin-fix-text-top margin-bottom aligned-text"
        >
          <p>
            wurde 1990 bei Hamburg geboren und sammelte früh Theatererfahrungen
            am kleinen Theater Bargteheide. Nach der Schule folgte eine
            kaufmännische Ausbildung und Tätigkeiten unter anderem als Koch und
            Rezeptionist in Berlin und Cefalù/Sizilien. 2015–2019 absolvierte er
            ein Schauspielstudium an der Hochschule für Musik und Darstellende
            Kunst in Frankfurt und verbrachte ein Gastsemester an der Zürcher
            Hochschule der Künste. In der Spielzeit 2017/18 trat er am
            Schauspiel Frankfurt im Rahmen des „Studiojahr Schauspiel“ in
            „Tintenherz“ als Schurke Basta unter der Regie von Rüdiger Pape auf
            und als Leiter des Fortgangs in „Einige Nachrichten an das All“
            unter der Regie von Marc Prätsch. Nach seinem Studium arbeitet Felix
            als freier Schauspieler.
          </p>
        </div>
        <div className="slider">
          <Slick ref={c => (this.slider = c)} {...settings}>
            <div>
              <Img fluid={this.props.data.i1.childImageSharp.slider} />
            </div>
            <div>
              <Img fluid={this.props.data.i2.childImageSharp.slider} />
            </div>
            <div>
              <Img fluid={this.props.data.i3.childImageSharp.slider} />
            </div>
            <div>
              <Img fluid={this.props.data.im1.childImageSharp.slider} />
            </div>
            <div>
              <Img fluid={this.props.data.i4.childImageSharp.slider} />
            </div>
            <div>
              <Img fluid={this.props.data.i5.childImageSharp.slider} />
            </div>
            <div className="portrait">
              <Img fluid={this.props.data.i6.childImageSharp.slider} />
            </div>
            <div className="portrait">
              <Img fluid={this.props.data.i7.childImageSharp.slider} />
            </div>
            <div className="portrait">
              <Img fluid={this.props.data.i8.childImageSharp.slider} />
            </div>
            <div>
              <Img fluid={this.props.data.i9.childImageSharp.slider} />
            </div>
          </Slick>
        </div>
        <h2 ref={this.h_produktionen} className="aligned-text">
          <span ref={this.m_produktionen}>Produktionen</span>
        </h2>
        <div
          ref={this.c_produktionen}
          className="text-right margin-fix-text aligned-text"
        >
          <p className="section">
            <strong>2019</strong> — Der fröhliche Weinberg
            <br />
            <small>
              von Carl Zuckmayer
              <br />
              Regie: Henriette Hörnigk / Hessisches Staatstheater Wiesbaden
              <br />
              Rolle: Stopski
            </small>
          </p>
          <p className="section">
            <strong>2019</strong> — Funkkolleg Religion Macht Politik
            <br />
            <small>
              von Lothar Bauerochse
              <br />
              Regie: Marlene Breuer / hr-iNFO (Hörspiel)
              <br />
              Rolle: Musa
            </small>
          </p>
          <p className="section">
            <strong>2018</strong> — Frankfurter Dramen
            <br />
            <small>
              von HfMDK
              <br />
              Regie: Tobias Lenel / Schauspiel Frankfurt (Spielfilm)
              <br />
              Rolle: Sven Böhnke
            </small>
          </p>
          <p className="section">
            <strong>2018</strong> — Einige Nachrichten an das All
            <br />
            <small>
              von Wolfram Lotz
              <br />
              Regie: Marc Prätsch / Schauspiel Frankfurt
              <br />
              Rolle: LdF (Leiter des Fortgangs)
            </small>
          </p>
          <p className="section">
            <strong>2017</strong> — Tintenherz
            <br />
            <small>
              von Cornelia Funke
              <br />
              Regie: Rüdiger Pape / Schauspiel Frankfurt
              <br />
              Rolle: Basta
            </small>
          </p>
          <p className="section">
            <strong>2017</strong> — Kommt ein Mann zur Welt
            <br />
            <small>
              von Martin Heckmanns
              <br />
              Regie: Werner Wölbern / Gallus Theater, Frankfurt a.M.
              <br />
              Rolle: Bruno und Brunos Stimmen
            </small>
          </p>
          <p className="section">
            <strong>2008</strong> — Die Dreigroschenoper
            <br />
            <small>
              von Bertolt Brecht
              <br />
              Regie: Barbara Wollrath-Kramer / TheaterTotal, Bochum
              <br />
              Rolle: Mackie Messer
            </small>
          </p>
          <p className="section">
            <strong>2003</strong> — Momo
            <br />
            <small>
              von Michael Ende
              <br />
              Regie: Kirsten Martensen / Kleines Theater Bargteheide
              <br />
              Rolle: Meister Hora
            </small>
          </p>
        </div>
        <h2 ref={this.h_kontakt} className="text-right aligned-text">
          <span ref={this.m_kontakt}>Kontakt</span>
        </h2>
        <div
          ref={this.c_kontakt}
          className="margin-fix-text margin-bottom-end aligned-text"
        >
          <p>
            <Mailto address="mail@felixvogel.info" />
          </p>
          <p>
            <a
              href="https://filmmakers.de/actor/view?rid=3f534c8bdbacd4de6e88295d50daded8&searchid=2060340232"
              target="_blank"
              rel="noopener noreferrer"
            >
              Filmmakers
            </a>
            <br />
            <a
              href="https://www.schauspielervideos.de/fullprofile/schauspieler-felix-vogel.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Schauspielervideos
            </a>
            <br />
            <a
              href="https://www.castupload.com/actors/felix-vogel"
              target="_blank"
              rel="noopener noreferrer"
            >
              Castupload
            </a>
            <br />
            <a
              href="https://www.castforward.de/members/profile/felix-vogel"
              target="_blank"
              rel="noopener noreferrer"
            >
              Castforward
            </a>
          </p>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export const img = graphql`
  fragment img on File {
    childImageSharp {
      intro: fluid(maxWidth: 900) {
        ...GatsbyImageSharpFluid
      }
      slider: fluid(maxWidth: 1035) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    i0: file(
      relativePath: { eq: "felix-vogel-copyright-jessi-schaefer-0.jpg" }
    ) {
      ...img
    }
    i1: file(
      relativePath: { eq: "felix-vogel-copyright-jessi-schaefer-1.jpg" }
    ) {
      ...img
    }
    i2: file(
      relativePath: { eq: "felix-vogel-copyright-jessi-schaefer-2.jpg" }
    ) {
      ...img
    }
    i3: file(
      relativePath: { eq: "felix-vogel-copyright-jessi-schaefer-3.jpg" }
    ) {
      ...img
    }
    i4: file(
      relativePath: { eq: "felix-vogel-copyright-jessi-schaefer-4.jpg" }
    ) {
      ...img
    }
    i5: file(
      relativePath: { eq: "felix-vogel-copyright-jessi-schaefer-5.jpg" }
    ) {
      ...img
    }
    i6: file(
      relativePath: { eq: "felix-vogel-copyright-jessi-schaefer-6.jpg" }
    ) {
      ...img
    }
    i7: file(
      relativePath: { eq: "felix-vogel-copyright-jessi-schaefer-7.jpg" }
    ) {
      ...img
    }
    i8: file(
      relativePath: { eq: "felix-vogel-copyright-jessi-schaefer-8.jpg" }
    ) {
      ...img
    }
    i9: file(
      relativePath: { eq: "felix-vogel-copyright-jessi-schaefer-9.jpg" }
    ) {
      ...img
    }
    i10: file(
      relativePath: { eq: "felix-vogel-copyright-jessi-schaefer-10.jpg" }
    ) {
      ...img
    }
    ir1: file(
      relativePath: { eq: "felix-vogel-copyright-robert-schnittko-1.jpg" }
    ) {
      ...img
    }
    im1: file(relativePath: { eq: "felix-vogel-copyright-max-woelky-1.jpg" }) {
      ...img
    }
  }
`
